// ** Reducers Imports
import layout from './layout'
import auth from './authentication'
import commonReducer from './commonSlice'
import headerActionReducer from './headerActionSlice'
import windowResizeReducer from './windowResizeSlice'
import listViewReducer from './listViewSlice'
import webTitleReducer from './webTitleSlice'
import channelReducer from './channelSlice'
import supportChatReducer from './supportChatSlice'

const rootReducer = {
  auth,
  layout,
  commonReducer,
  headerActionReducer,
  windowResizeReducer,
  listViewReducer,
  webTitleReducer,
  channelReducer,
  supportChatReducer
}

export default rootReducer

import React, { Component } from 'react';
import { getCookie, setCookie } from '../helper/commonFunction';
import { liveDomain } from '../helper/commonApi';


class Errorboundry extends Component {
    constructor(props) {
        super(props);

        this.state = {
            hasError: false
        };
    }

    componentDidCatch(error, info) {
        if (error && !this.state.hasError) {
            this.setState({
                hasError: true
            });
        }
    }

    render() {
        if (this.state.hasError) {
            if (liveDomain && !getCookie('is_error')) {
                setCookie('is_error', 1, 60);
                location.reload(true);
            } else {
                alert('Something went wrong so required reloading.')
                location.reload(true);
            }
            return;
        }
        return this.props.children;
    }
}

export default Errorboundry;

let apiEndpoint = ''
let commonApi = ''
let bucketPathUrl = ''
let munimApiEndpoint = ''
let liveDomain = false
const bucketUrl = 'https://cdn.themunim.com/ecom/'
const hostname = window.location.hostname
const isLive = window.location.hostname !== 'localhost'
const isGetData = window.location.pathname.includes('/dashboard') || window.location.pathname === '/'

if (hostname === 'localhost') {
    apiEndpoint = 'http://localhost:6070/api'
    commonApi = 'http://localhost:6070/'
    munimApiEndpoint = 'https://devapi.themunim.com/api'
    bucketPathUrl = `${bucketUrl}local/`

    /*For Test Server  */
    // apiEndpoint = 'https://testecomapi.themunim.com/api'
    // commonApi = 'https://testecomapi.themunim.com/'
    // bucketPathUrl = `${bucketUrl}dev/`
    // munimApiEndpoint = 'https://devapi.themunim.com/api'
    // munimApiEndpoint = 'https://api.themunim.com/api'
} else if (hostname === 'devecom.themunim.com') {
    apiEndpoint = 'https://devecomapi.themunim.com/api'
    commonApi = 'https://devecomapi.themunim.com/'
    bucketPathUrl = `${bucketUrl}dev/`
    munimApiEndpoint = 'https://devapi.themunim.com/api'
} else if (hostname === 'ecommerce.themunim.com') {
    liveDomain = true
    apiEndpoint = 'https://ecommerceapi.themunim.com/api'
    commonApi = 'https://ecommerceapi.themunim.com/'
    bucketPathUrl = `${bucketUrl}live/`
    munimApiEndpoint = 'https://api.themunim.com/api'
}

module.exports = {
    apiEndpoint,
    commonApi,
    bucketPathUrl,
    bucketUrl,
    isLive,
    munimApiEndpoint,
    isGetData,
    liveDomain
}
